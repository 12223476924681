import { useNavigate, useRevalidator } from '@remix-run/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';

import type {
  DtoCover,
  DtoStackDetailResponse,
} from '@lp-lib/api-service-client/public';
import { type DtoReorderStackCoursesRequest } from '@lp-lib/api-service-client/public';

import placeholder from '../../../../assets/img/placeholder/game-cover.png';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { SegmentedControl } from '../../../common/SegmentedControl';
import { type Option } from '../../../common/Utilities';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { GameIcon } from '../../../icons/GameIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { ActionsMenu } from '../Components/ActionMenu';
import { PageHeader } from '../Components/PageHeader';
import { useTriggerAddCoursesModal } from '../useTriggerAddCoursesToStackModal';
import { AssignedGroupRow } from './AssignedGroupRow';
import {
  AssignedMemberRow,
  useNavigateToLearnerProfile,
} from './AssignedMemberRow';

const logger = getLogger();

interface StackDetailsPageProps {
  stack: DtoStackDetailResponse;
}

interface StackCourseRowProps {
  course: {
    courseId: string;
    courseName: string;
    sequenceOrder: number;
    cover: DtoCover;
  };
  onRemove: (courseId: string) => void;
  isReordering?: boolean;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  index?: number;
}

function StackNameField({
  name,
  onSave,
}: {
  name: string;
  onSave: (newName: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(name);

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  const handleSave = (newName: string) => {
    const trimmedName = newName.trim();
    if (trimmedName && trimmedName !== name) {
      onSave(trimmedName);
    }
    setEditing(false);
  };

  if (!editing) {
    return (
      <div className='flex items-center gap-2'>
        <h1 className='text-2xl font-bold'>{name}</h1>
        <button
          className='p-2 rounded hover:bg-[#232325] transition-colors'
          onClick={() => setEditing(true)}
          type='button'
        >
          <EditIcon className='w-5 h-5 fill-current text-white/70 hover:text-white' />
        </button>
      </div>
    );
  }

  return (
    <input
      className='field mb-0 w-50 h-8'
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => handleSave(inputValue)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSave(inputValue);
        } else if (e.key === 'Escape') {
          setEditing(false);
          setInputValue(name); // Reset to current name
        }
      }}
      autoFocus
    />
  );
}
function StackCourseRow({
  course,
  onRemove,
  isReordering = false,
  moveRow,
  index = 0,
}: StackCourseRowProps) {
  const rowRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [{ isDragging }, drag] = useDrag({
    type: 'course-row',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isReordering,
  });

  const [, drop] = useDrop({
    accept: 'course-row',
    hover(item: { index: number }, monitor) {
      if (!rowRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = rowRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow?.(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(rowRef));

  const menuActions = [
    {
      label: 'Course Details',
      icon: <GameIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: () =>
        navigate(
          $path('/learning/admin/my-courses/:id', { id: course.courseId })
        ),
    },
    {
      label: 'Edit Course',
      icon: <EditIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: () =>
        navigate($path('/trainings/:id/edit', { id: course.courseId })),
    },
    {
      label: 'Remove Course',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onRemove(course.courseId),
      variant: 'danger' as const,
    },
  ];

  return (
    <div
      ref={rowRef}
      className={`bg-[#17171A] hover:bg-[#1f1f22] transition-colors
                    border border-lp-gray-003
                    rounded-lg pr-4 py-3
                    flex justify-between items-center relative
                    ${isDragging ? 'opacity-50' : 'opacity-100'}
                    ${isReordering ? 'cursor-move' : 'hover:cursor-pointer'}`}
      onClick={() => {
        if (!isReordering && !isDragging)
          navigate(
            $path('/learning/admin/my-courses/:id', { id: course.courseId })
          );
      }}
    >
      <div className={`flex items-center gap-2 ${isReordering ? 'pl-4' : ''}`}>
        {isReordering && <MenuIcon className='w-5 h-5 text-white/50' />}
        <span className='text-xs text-white/90 w-6 text-right'>
          {String(course.sequenceOrder + 1).padStart(2, '0')}
        </span>
        <img
          src={course.cover?.url || placeholder}
          alt={course.courseName}
          className='w-21 h-12 rounded-lg object-cover mr-2'
        />
        <div className='text-white text-sms font-medium'>
          {course.courseName || 'Untitled Course'}
        </div>
      </div>

      {/* Actions */}
      <div className={`relative ${isReordering ? 'opacity-0' : 'opacity-100'}`}>
        <ActionsMenu actions={menuActions} />
      </div>
    </div>
  );
}

export function StackDetailsPage({ stack }: StackDetailsPageProps) {
  const revalidator = useRevalidator();
  const [optimisticName, setOptimisticName] = useState(stack.name);
  const [isReordering, setIsReordering] = useState(false);
  const [orderedCourses, setOrderedCourses] = useState(stack.courses);
  const triggerAddCoursesModal = useTriggerAddCoursesModal();
  const navigateToLearnerProfile = useNavigateToLearnerProfile();

  const handleAddCourses = async (courseIds: string[]) => {
    try {
      await apiService.learning.addCoursesToStack(stack.id, { courseIds });
      toast.success('Courses added to stack');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add courses to stack', error);
      toast.error('Failed to add courses');
    }
  };

  const handleAddCoursesButtonClick = () => {
    const existingCourseIds = stack.courses.map((course) => course.courseId);

    triggerAddCoursesModal({
      onConfirm: handleAddCourses,
      existingCourseIds,
    });
  };

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setOrderedCourses((prevCourses) => {
      const newCourses = [...prevCourses];
      const [removed] = newCourses.splice(dragIndex, 1);
      newCourses.splice(hoverIndex, 0, removed);
      return newCourses.map((course, index) => ({
        ...course,
        sequenceOrder: index,
      }));
    });
  }, []);

  const handleSaveOrder = async () => {
    try {
      await handleReorderCourses(
        orderedCourses.map((course) => ({
          courseId: course.courseId,
          sequenceOrder: course.sequenceOrder,
        }))
      );
      setIsReordering(false);
    } catch (error) {
      logger.error('Failed to save course order', error);
    }
  };

  const handleCancelReorder = () => {
    setOrderedCourses(stack.courses);
    setIsReordering(false);
  };

  useEffect(() => {
    setOrderedCourses(stack.courses);
  }, [stack.courses]);

  const tabOptions: Option<'groups' | 'members'>[] = [
    { label: 'Members', value: 'members' },
    { label: 'Groups', value: 'groups' },
  ];

  const [activeTab, setActiveTab] = useState(tabOptions[0]);

  const [unassigningGroupId, setUnassigningGroupId] = useState<string | null>(
    null
  );

  const handleUpdateStackName = async (newName: string) => {
    try {
      setOptimisticName(newName);
      await apiService.learning.updateStackName(stack.id, { name: newName });
      toast.success('Stack name updated');
      revalidator.revalidate();
    } catch (error) {
      setOptimisticName(stack.name);
      logger.error('Failed to update stack name', error);
      toast.error('Failed to update stack name');
    }
  };

  async function handleRemoveCourse(courseId: string) {
    try {
      await apiService.learning.removeCourseFromStack(stack.id, courseId);
      toast.success('Course removed from stack');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to remove course', error);
      toast.error('Failed to remove course');
    }
  }

  async function handleUnassignMember(userId: string) {
    try {
      await apiService.learning.unassignMemberFromStack(stack.id, userId);
      toast.success('Member unassigned from stack');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to unassign member', error);
      toast.error('Failed to unassign member');
    }
  }

  async function handleUnassignGroup(groupId: string) {
    try {
      setUnassigningGroupId(groupId);
      await apiService.learning.unassignGroupFromStack(stack.id, groupId);
      toast.success('Group unassigned from stack');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to unassign group', error);
      toast.error('Failed to unassign group');
    } finally {
      setUnassigningGroupId(null);
    }
  }

  async function handleReorderCourses(
    newOrder: { courseId: string; sequenceOrder: number }[]
  ) {
    try {
      const payload: DtoReorderStackCoursesRequest = { courses: newOrder };
      await apiService.learning.reorderStackCourses(stack.id, payload);
      toast.success('Courses reordered');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to reorder courses', error);
      toast.error('Failed to reorder courses');
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative'>
        {/* Header section */}
        <div className='flex items-center justify-between w-full px-4 pt-4 md:pt-8 pb-4'>
          <div className='flex flex-col gap-1'>
            <PageHeader
              title='Stacks'
              path={'/learning/admin/my-courses?tab=stacks'}
            />
            <StackNameField
              name={optimisticName}
              onSave={handleUpdateStackName}
            />
          </div>
        </div>

        {/* Scrollable content container */}
        <div className='flex-1 overflow-y-auto pb-40 md:pb-0'>
          {/* Courses section */}
          <div className='px-4 pt-4'>
            <div className='flex items-center justify-between mb-4'>
              <h2 className='text-xl font-bold'>
                Courses ({stack.courses.length})
              </h2>
              <div className='flex gap-2'>
                {isReordering ? (
                  <>
                    <button
                      className='btn-primary px-4 py-2'
                      onClick={handleSaveOrder}
                      type='button'
                    >
                      Save Course Order
                    </button>
                    <button
                      className='btn-secondary px-4 py-2'
                      onClick={handleCancelReorder}
                      type='button'
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className='btn-secondary px-4 py-2'
                      onClick={() => setIsReordering(true)}
                      type='button'
                    >
                      Reorder Courses
                    </button>
                    <button
                      className='btn-secondary px-4 py-2'
                      onClick={handleAddCoursesButtonClick}
                      type='button'
                    >
                      Add Courses
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className='flex flex-col gap-2'>
              {orderedCourses.map((courseItem, index) => (
                <StackCourseRow
                  key={courseItem.courseId}
                  course={courseItem}
                  onRemove={handleRemoveCourse}
                  isReordering={isReordering}
                  moveRow={moveRow}
                  index={index}
                />
              ))}
            </div>
          </div>
          {/* Groups or Members section */}
          <div className='px-4 md:px-8 py-6 md:py-10'>
            {/* Segmented Control */}
            <div className='w-full flex justify-start pb-4'>
              <div className='w-54'>
                <SegmentedControl
                  options={tabOptions}
                  value={activeTab}
                  onChange={(value) => setActiveTab(value)}
                  styles={{
                    glider: 'bg-lp-gray-006 rounded-lg',
                    track: 'rounded-xl p-1 border border-secondary',
                    option: 'px-4 py-2 transition-colors text-sms',
                    selectedOption: 'text-white',
                    unselectedOption: 'text-gray-400 hover:text-white',
                  }}
                />
              </div>
            </div>

            {activeTab.value === 'groups' ? (
              <>
                <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6'>
                  Groups ({stack.assignedGroups.length})
                </h2>
                {/* Header row for groups */}
                <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
                  <div className='w-1/5'>Group Name</div>
                  <div className='w-1/5'>Assigned By</div>
                  <div className='w-1/5'>Assigned On</div>
                  <div className='w-1/5'>Members</div>
                  <div className='w-12'></div>
                </div>
                <div className='flex flex-col gap-2.5 mt-2'>
                  {stack.assignedGroups.map((group) => (
                    <AssignedGroupRow
                      key={group.groupId}
                      group={group}
                      isUnassigning={group.groupId === unassigningGroupId}
                      onDelete={() => handleUnassignGroup(group.groupId)}
                      deleteLabel='Unassign From Group'
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6'>
                  Assigned Members ({stack.assignedMembers.length})
                </h2>
                {/* Header row for members */}
                <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
                  <div className='flex-1 pr-1'>Name</div>
                  <div className='flex-1'>Group Assignment</div>
                  <div className='flex-1'>Assigned On</div>
                  <div className='flex-1'>Assigned By</div>
                  <div className='flex-1'>Completed</div>
                  <div className='flex-1'>Progress</div>
                  <div className='w-12'></div>
                </div>
                <div className='flex flex-col gap-2.5 mt-2'>
                  {stack.assignedMembers.map((member) => (
                    <AssignedMemberRow
                      key={member.userId}
                      uid={member.userId}
                      name={
                        member.name.trim() === '' ? member.email : member.name
                      }
                      group={member.group}
                      assignedOn={new Date(
                        member.assignedOn
                      ).toLocaleDateString()}
                      addedBy={member.addedBy}
                      completed={member.progressPct === 100}
                      directProgress={member.progressPct}
                      isDirect={true}
                      onDirectUnassign={() =>
                        handleUnassignMember(member.userId)
                      }
                      onClick={() => navigateToLearnerProfile(member.userId)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
